// extracted by mini-css-extract-plugin
export var animation = "about-page-module--animation--e56e7";
export var backgroundWrapper = "about-page-module--backgroundWrapper--31250";
export var calloutFlex = "about-page-module--calloutFlex--73e0d";
export var cardSection = "about-page-module--cardSection--c6e4e";
export var extraTopPadding = "about-page-module--extraTopPadding--9756b";
export var h1 = "about-page-module--h1--07402";
export var h2 = "about-page-module--h2--1c128";
export var h3 = "about-page-module--h3--39e1a";
export var h4 = "about-page-module--h4--29943";
export var largeHexPattern = "about-page-module--largeHexPattern--40849";
export var leftAlignSection = "about-page-module--leftAlignSection--561ea";
export var p = "about-page-module--p--386de";
export var rightAlignSection = "about-page-module--rightAlignSection--96e07";
export var rightAlignedHeading = "about-page-module--rightAlignedHeading--729a7";
export var rocketContainer = "about-page-module--rocketContainer--0b48d";
export var section = "about-page-module--section--11ac1";
export var sectionContent = "about-page-module--sectionContent--2f819";
export var teamBgWrapper = "about-page-module--teamBgWrapper--33712";
export var triangleBackgroundWrapper = "about-page-module--triangleBackgroundWrapper--5c0e5";