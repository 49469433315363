import React from 'react'
import '../styles/animate.css'

const AboutPageIcon = () => {
  return (
    <svg width="384" height="329" xmlns="http://www.w3.org/2000/svg" className="aboutPageIcon">
      <g fillRule="nonzero" fill="none">
        <ellipse
          className="tertiary"
          cx="105.5"
          cy="99.5"
          rx="37.5"
          ry="36.5"
        />
        <path
          d="M192 266C86.182 266 .36 294.186 0 329h384c-.36-34.814-86.182-63-192-63z"
          fill="#718096"
        />
        <ellipse
          cx="35.5"
          cy="10"
          rx="35.5"
          ry="9.662"
          opacity=".1"
          transform="translate(121 294)"
          fill="#000"
        />
        <ellipse
          cx="35.5"
          cy="10"
          rx="35.5"
          ry="9.662"
          opacity=".1"
          transform="translate(199 276)"
          fill="#000"
        />
        <ellipse
          cx="32"
          cy="8.5"
          rx="32"
          ry="8.5"
          opacity=".6"
          transform="translate(124 296)"
          fill="#E2E8F0"
        />
        <ellipse
          cx="32"
          cy="8.5"
          rx="32"
          ry="8.5"
          opacity=".6"
          transform="translate(202 277)"
          fill="#E2E8F0"
        />
        <ellipse
          className="animated flash infinite"
          fill="#CBD5E0"
          cx="61"
          cy="127.5"
          rx="3"
          ry="2.5"
        />
        <ellipse fill="#CBD5E0" cx="329" cy="248.5" rx="3" ry="2.5" />
        <ellipse
          className="animated flash infinite"
          fill="#CBD5E0"
          cx="183"
          cy="148.5"
          rx="3"
          ry="2.5"
        />
        <circle
          className="animated flash infinite tertiary"
          cx="337"
          cy="131"
          r="2"
        />
        <circle
          className="animated flash infinite tertiary"
          cx="68"
          cy="218"
          r="2"
        />
        <path
          className="secondary"
          d="M42 255.911h-.911V255h-.178v.911H40v.178h.911V257h.178v-.911H42zM302 189.911h-.911V189h-.178v.911H300v.178h.911V191h.178v-.911H302zM369 177.911h-.911V177h-.178v.911H367v.178h.911V179h.178v-.911H369zM225 212.911h-.911V212h-.178v.911H223v.178h.911V214h.178v-.911H225zM349 216.911h-.911V216h-.178v.911H347v.178h.911V218h.178v-.911H349zM200 235.911h-.911V235h-.178v.911H198v.178h.911V237h.178v-.911H200zM256 236.911h-.911V236h-.178v.911H254v.178h.911V238h.178v-.911H256zM43 161.911h-.911V161h-.178v.911H41v.178h.911V163h.178v-.911H43z"
        />
        <path fill="#E6E6E6" d="M301 102h1v25h-1z" />
        <path
          fill="#2D3748"
          d="M272 169l-12 26.307 2.348 3.722-.783 1.24 5.479 2.731v-1.489l2.086-.496 8.87-15.387z"
        />
        <path
          d="M262.791 201s-9.212 12.887 1.084 14.832c10.296 1.945 1.896-13.616 1.896-13.616l-2.98-1.216z"
          fill="#E6E6E6"
        />
        <path
          d="M275.686 193.436l-3.086 9.872-1.286 12.656-2.314 4.557 2.571 20.503 4.115 4.05 1.8 20.25v9.113l4.885 8.353-6.291 8.791a2.764 2.764 0 0 0-.225 2.865 2.849 2.849 0 0 0 2.457 1.552c1.39.036 3.22-.35 5.602-1.564 7.457-3.797 7.2-7.34 7.2-7.34s4.629 0 4.886-3.544c.257-3.544-2.829-28.857-2.829-28.857l-2.828-10.884v-29.617h1.028l.515 25.313 2.314 9.366.257 4.05 3.343 40.5s14.914 4.051 15.686.76c.771-3.29-5.657-5.315-5.657-5.315l3.857-7.341V267.35l-1.286-3.038-2.314-20.503 3.857-4.05 2.057-2.532v-20.25l-2.571-7.847-.772-6.582-4.114-10.125s-18.772-6.075-30.857 1.013z"
          fill="#2D3748"
        />
        <path
          d="M308 138c0 8.256-5.472 15.567-13.54 18.09-8.069 2.523-16.886-.32-21.818-7.033-4.931-6.714-4.848-15.762.207-22.388l.005-.005c5.016-6.574 13.794-9.275 21.776-6.7 7.982 2.575 13.371 9.845 13.37 18.036z"
          className="secondary"
        />
        <path
          d="M305.887 130.698l-1.59 9.073c-11.943-30.9-30.943-11.92-31.27-11.587a19.51 19.51 0 0 1 17.125-7.774c6.67.51 12.61 4.394 15.735 10.288z"
          opacity=".1"
          fill="#000"
        />
        <path
          d="M306 128.123L304.404 137c-11.995-30.235-31.074-11.662-31.404-11.337 4.008-5.235 10.501-8.106 17.199-7.607 6.697.5 12.663 4.3 15.801 10.067z"
          fill="#FFF"
        />
        <path
          d="M303.873 125.097l2.62 9.235s-5.437 16.554-30.645 17.13l-.826 2.365-2.765.766-1.257 5.363 3.52 33.965s-1.51 3.32 1.005 3.065c0 0 16.59-5.618 30.917-.51 0 0 3.016 1.276 2.262-1.533 0 0 10.055-6.384 9.3-13.024-.753-6.64.755-37.54.755-37.54s1.508-8.939-3.017-10.982c0 0-6.645-9.45-11.869-8.3z"
          fill="#A0AEC0"
        />
        <path
          d="M298.26 188s-15.889 1.715-11.49 11.185c4.398 9.47 12.23-7.99 12.23-7.99l-.74-3.195z"
          fill="#E6E6E6"
        />
        <path
          d="M306.644 148.56s-2.856 3.265-1.817 8.538c1.038 5.273 4.933 17.074 4.933 17.074L296 186.476l4.154 5.524 18.692-9.29 4.154-2.512a101.401 101.401 0 0 0-7.628-29.543l-.68-1.593s-4.932-2.008-8.048-.502z"
          fill="#2D3748"
        />
        <circle fill="#2D3748" cx="305" cy="135" r="5" />
        <circle fill="#E6E6E6" cx="301" cy="102" r="3" />
        <path
          fill="#2D3748"
          d="M213 153.911h-.911V153h-.178v.911H211v.178h.911V155h.178v-.911H213zM287 130.911h-.911V130h-.178v.911H285v.178h.911V132h.178v-.911H287zM280 137.911h-.911V137h-.178v.911H278v.178h.911V139h.178v-.911H280z"
        />
        <circle fill="#CBD5E0" cx="248" cy="117" r="2" />
        <path
          d="M159 130.058C159 253 143.551 253 124.5 253c-9.85 0-18.735 0-25.02-16.983-1.806-4.88-3.402-11.174-4.74-19.275-3.012-18.21-4.74-45.577-4.74-86.684 0-15.807 1.87-31.304 4.74-45.777a292.813 292.813 0 0 1 4.74-20.109 326.082 326.082 0 0 1 6.848-21.995 338.99 338.99 0 0 1 4.74-12.745C118.249 11.258 124.5 0 124.5 0S159 62.159 159 130.058z"
          fill="#E2E8F0"
        />
        <path
          d="M111.622 28.354v214.339h-4.487V41.098c1.5-4.543 3.016-8.807 4.487-12.744z"
          opacity=".1"
          fill="#000"
        />
        <circle fill="#3F3D56" cx="124" cy="85" r="19" />
        <path
          d="M165.715 240.575c-4.309-10.287-9.84-12.062-13.457-11.908V227H95.742v1.667c-3.616-.154-9.148 1.62-13.457 11.908C75.288 257.283 86.591 286 86.591 286s-6.04-40.05 14.02-40.314a23.499 23.499 0 0 0 6.292 5.538v10.772h32.394v-9.791a23.565 23.565 0 0 0 8.093-6.52c20.06.264 14.019 40.315 14.019 40.315s11.303-28.717 4.306-45.425z"
          className="secondary"
        />
        <circle className="primary" cx="124" cy="85" r="15" />
      </g>
    </svg>
  )
}

export default AboutPageIcon
