import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './team-hex-grid.module.css'
import TeamHexRow from '../teamHexRow'
import shuffle from 'lodash/shuffle'
import chunk from 'lodash/chunk'
import { useWindowWidth } from '../../hooks/useWindowWidth'

const TeamHexGrid = () => {
  const windowWidth = useWindowWidth()
  const minDisplayWidth = 600

  const data = useStaticQuery(graphql`
    query {
      people: allContentfulPeople {
        nodes {
          id
          firstName
          jobTitle
          photo {
            gatsbyImageData(
              layout: FIXED
              height: 160
              width: 140
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
        }
      }
    }
  `)
  const people = data.people.nodes

  const tileWidth = 140

  const [randomPeople, setRandomPeople] = useState([])
  const [rows, setRows] = useState([])

  const tilesPerRow = Math.floor(windowWidth / tileWidth) + 1

  let padding = Math.max(
    Math.ceil((tilesPerRow - (Math.ceil(Math.sqrt(people.length)) + 2)) / 2),
    2
  )

  const membersPerRow = tilesPerRow - padding

  if (membersPerRow + padding * 2 >= tilesPerRow) {
    padding = 1
  }

  useEffect(() => {
    setRandomPeople(shuffle(people))
  }, [people])

  useEffect(() => {
    setRows(chunk(randomPeople, membersPerRow))
  }, [randomPeople, membersPerRow])

  // Check to see if we should show the team hexagon grid based on window width
  if (windowWidth <= minDisplayWidth) {
    return <div className={styles.team}></div>
  }

  return (
    <div
      className={styles.team}
      style={{
        marginBottom: -24 * rows.length,
        paddingTop: 32,
      }}
    >
      {rows.map((row, i) => {
        return (
          <TeamHexRow
            key={row.map((person) => person.id)}
            tilesPerRow={tilesPerRow}
            people={row}
            index={i}
          />
        )
      })}
    </div>
  )
}

export default TeamHexGrid
