import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/pageHeader'
import Callout from '../components/callout'
import Section from '../components/section'
import Button from '../components/button'
import AboutPageIcon from '../static/AboutPageIcon'
import Performance from '../components/icons/Performance'
import PhoneIncrease from '../components/icons/PhoneIncrease'
import FullWidthSection from '../components/fullWidthSection'
import FeatureCard from '../components/featureCard'
import * as styles from './styles/about-page.module.css'
import HexAnimation from '../components/hexAnimation'
import LargeHexPattern from '../components/icons/LargeHexPattern'
import TeamHexGrid from '../components/teamHexGrid'
import { getTextCopy, getHtmlCopy } from '../helpers'

const About = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
    callout,
  } = data.aboutPage
  const cards = data.aboutFeatureCards.nodes
  const { contactPageSlug } = data.contactPage

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <div className={styles.backgroundWrapper}>
          <Section minPadding>
            <PageHeader
              header={getTextCopy(content, 'page.about.header')}
              subheader={getHtmlCopy(content, 'page.about.subheader')}
            />
          </Section>
        </div>
        <Section minPadding>
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div
                className={styles.h3}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.about.whoHeader'),
                }}
              />
              <div
                className={styles.p}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.about.whoBody'),
                }}
              />
            </div>
            <div className={styles.rocketContainer}>
              <AboutPageIcon />
            </div>
          </div>
        </Section>
        <FullWidthSection
          heading={getTextCopy(content, 'page.about.howHeader')}
          description={getHtmlCopy(content, 'page.about.howBody')}
          dark
          icon={<Performance size={36} />}
        />
        <Section>
          <div className={styles.animation}>
            <HexAnimation />
          </div>
          <div className={styles.rightAlignSection}>
            <div
              className={styles.h1}
              id={styles.rightAlignedHeading}
              dangerouslySetInnerHTML={{
                __html: getHtmlCopy(content, 'page.about.middleLeftHeader'),
              }}
            />
            <div>
              <div
                className={styles.h3}
                id={styles.extraTopPadding}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.about.whatHeader'),
                }}
              />

              <div
                className={styles.p}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.about.whatBody'),
                }}
              />
            </div>
          </div>
        </Section>
        <FullWidthSection
          heading={getTextCopy(content, 'page.about.scaleHeader')}
          description={getHtmlCopy(content, 'page.about.scaleBody')}
          icon={<PhoneIncrease size={40} />}
        />
        <Section>
          <div className={styles.leftAlignSection}>
            <div>
              <div
                className={styles.h3}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.about.whyHeader'),
                }}
              />
              <div
                className={styles.p}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.about.whyBody'),
                }}
              />
            </div>
            <div className={styles.largeHexPattern}>
              <LargeHexPattern />
            </div>
          </div>
        </Section>
        <div className={styles.triangleBackgroundWrapper}>
          <Section>
            <div className={styles.cardSection}>
              {cards.map((card, index) => (
                <FeatureCard
                  key={card.header.child.html}
                  icon={card.icon.svg.content}
                  header={card.header.child.html}
                  subheader={card.subheader.child.html}
                />
              ))}
            </div>
          </Section>
        </div>
        <div className={styles.calloutFlex}>
          <div
            className={styles.h2}
            dangerouslySetInnerHTML={{
              __html: getHtmlCopy(content, 'page.about.inlineCalloutHeader'),
            }}
          />
          <Link to={contactPageSlug}>
            <Button action="secondary">
              {getTextCopy(content, 'page.about.inlineCalloutButton')}
            </Button>
          </Link>
        </div>
        <div className={styles.teamBgWrapper}>
          <TeamHexGrid />
        </div>
      </Layout>
    </>
  )
}

export default About

export const query = graphql`
  {
    aboutPage: contentfulPage(name: { eq: "page.about" }) {
      ...pageFields
    }
    aboutFeatureCards: allContentfulFeatureCard(
      filter: { type: { eq: "about" } }
    ) {
      nodes {
        header {
          child: childMarkdownRemark {
            html
          }
        }
        subheader {
          child: childMarkdownRemark {
            html
          }
        }
        icon {
          svg {
            content
          }
        }
      }
    }
    contactPage: contentfulPage(name: { eq: "page.contact" }) {
      contactPageSlug: slug
    }
  }
`
