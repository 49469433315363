import React from 'react'
import BlankTeamMember from '../blankTeamMember'
import TeamMember from '../teamMember'

const TeamHexRow = ({ people, tilesPerRow, index }) => {
  let hexagons = []

  people.forEach((person) => {
    hexagons.push(
      <TeamMember
        key={person.id}
        name={person.firstName}
        position={person.jobTitle}
        image={person.photo !== null && person.photo.gatsbyImageData}
      />
    )
  })

  let length = tilesPerRow - people.length

  for (let i = 0; i < length + 1; i++) {
    if (i % 2 === 0) {
      hexagons.push(<BlankTeamMember key={i} />)
    } else {
      hexagons.unshift(<BlankTeamMember key={i} />)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        top: index * -36,
        left: index % 2 === 0 ? 72 : 0,
      }}
    >
      {hexagons}
    </div>
  )
}

export default TeamHexRow
