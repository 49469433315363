import { useState, useEffect } from 'react'

export function useWindowWidth() {
  const isClient = typeof window === 'object'
  const initialWidth = isClient ? window.innerWidth : undefined
  const [windowWidth, setWindowWidth] = useState(initialWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    if (isClient) {
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (isClient) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [isClient])

  return windowWidth
}
