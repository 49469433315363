import React, { Component } from 'react'
import * as styles from './team-member.module.css'

class TeamMember extends Component {
  render() {
    const { name, position, image } = this.props
    const teamMemberImage = image.images.fallback.src

    return (
      <div className={styles.teamMember}>
        <div className={styles.teamMemberInnerOne}>
          <div
            className={styles.teamMemberInnerTwo}
            style={{
              backgroundImage: `url(${teamMemberImage})`,
            }}
          >
            <div className={styles.overlay}>
              <p className={styles.overlayName}>{name}</p>
              <p className={styles.overlayPosition}>{position}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TeamMember
