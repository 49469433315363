import React, { Component } from 'react'
import cn from 'classnames'
import * as styles from './full-width-section.module.css'
import CircleBackground from '../circleBackground'

class FullWidthSection extends Component {
  render() {
    const { heading, description, icon, dark } = this.props
    return (
      <div className={cn(styles.wrapper, dark && styles.isDark)}>
        <div className={styles.icon}>
          <CircleBackground size={68}>{icon}</CircleBackground>
        </div>
        <div className={styles.heading}>{heading}</div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    )
  }
}

export default FullWidthSection
