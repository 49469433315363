import React, { useState, useEffect } from 'react'
import sample from 'lodash/sample'

const BlankTeamMember = () => {
  const [randomColor, setRandomColor] = useState()

  useEffect(() => {
    const newRandomColor = sample(['#e2e8f0', '#cbd5e0', '#a0aec0'])
    setRandomColor(newRandomColor)
  }, [])

  return (
    <div
      style={{
        backgroundColor: randomColor,
        position: 'relative',
        height: '160px',
        width: '140px',
        margin: '0 2px',
        overflow: 'hidden',
        visibility: 'hidden',
        transform: 'rotate(-120deg)',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          transform: 'rotate(-60deg)',
        }}
      >
        <div
          style={{
            backgroundColor: randomColor,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            visibility: 'visible',
            transform: 'rotate(-60deg)',
            backgroundPosition: '50%',
          }}
        ></div>
      </div>
    </div>
  )
}

export default BlankTeamMember
