import React from 'react'
import classNames from 'classnames'
import * as styles from './circle-background.module.css'

const CircleBackground = ({ children, size, className, color }) => {
  const circleBackgroundClasses = {
    [styles.circleBackground]: true,
    [styles[className]]: className,
  }

  return (
    <div
      className={classNames(circleBackgroundClasses)}
      style={{
        width: size,
        height: size,
        backgroundColor: color && color,
      }}
    >
      {children}
    </div>
  )
}

export default CircleBackground
